/*----------------------------------------------------

1. Global Area 
2. Header Section
=====================================================================*/
/*=========
Font load
===========*/
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,531;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,531;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,600,500,700|Roboto:100,300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
/*=========
Color Code
===========*/
/*global area*/
/*----------------------------------------------------*/
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  background: #f9f9f9;
  color: #2f3146;
  font-family: "Jost", sans-serif;
  -moz-osx-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  background-size: cover;
  background-image: url("./img/qbg.jpg");
}

::selection {
  color: #fff;
  background-color: #ed1b34;
}

::-moz-selection {
  color: #fff;
  background-color: #ed1b34;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s all ease-in-out;
}
a:hover,
a:focus {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  cursor: pointer;
}

.form-control:focus,
button:visited,
button.active,
button:hover,
button:focus,
input:visited,
input.active,
input:hover,
input:focus,
textarea:hover,
textarea:focus,
a:hover,
a:focus,
a.active,
select,
select:hover,
select:focus,
select:visited {
  outline: none;
  box-shadow: none;
  text-decoration: none;
  color: inherit;
}

.form-control {
  box-shadow: none;
}

p,
label {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "Jost", sans-serif;
}

.pt150 {
  padding-top: 150px;
}

.multisteps-form__panel[data-animation="scaleOut"] {
  transform: scale(1.1);
}

.multisteps-form__panel[data-animation="scaleOut"].js-active {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  transform: scale(1);
}

.multisteps-form__panel[data-animation="slideHorz"] {
  left: -50px;
}

.multisteps-form__panel[data-animation="slideHorz"].js-active {
  transition-property: all;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0.2, 1.13, 0.38, 1.43);
  transition-delay: 0s;
  left: 0;
}

.multisteps-form__panel[data-animation="slideVert"] {
  top: 30px;
}

.multisteps-form__panel[data-animation="slideVert"].js-active {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  top: 0;
}

.multisteps-form__panel[data-animation="fadeIn"].js-active {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: linear;
  transition-delay: 0s;
}

.multisteps-form__panel[data-animation="scaleIn"] {
  transform: scale(0.9);
}

.multisteps-form__panel[data-animation="scaleIn"].js-active {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  transform: scale(1);
}

/* ==========================================================================
multistep css
========================================================================== */
.multisteps-form__progress {
  padding-top: 60px;
  height: 100%;
}

.multisteps-form__panel {
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
  overflow: hidden;
  height: 0;
}
.multisteps-form__panel.js-active {
  opacity: 1;
  visibility: visible;
  overflow: visible;
  height: auto;
}
.multisteps-form__panel[data-animation="scaleOut"] {
  transform: scale(1.1);
}
.multisteps-form__panel[data-animation="scaleOut"].js-active {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  transform: scale(1);
}
.multisteps-form__panel[data-animation="slideHorz"] {
  left: -50px;
}
.multisteps-form__panel[data-animation="slideHorz"].js-active {
  transition-property: all;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0.2, 1.13, 0.38, 1.43);
  transition-delay: 0s;
  left: 0;
}
.multisteps-form__panel[data-animation="slideVert"] {
  top: 30px;
}
.multisteps-form__panel[data-animation="slideVert"].js-active {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  top: 0;
}
.multisteps-form__panel[data-animation="fadeIn"].js-active {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: linear;
  transition-delay: 0s;
}
.multisteps-form__panel[data-animation="scaleIn"] {
  transform: scale(0.9);
}
.multisteps-form__panel[data-animation="scaleIn"].js-active {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  transform: scale(1);
}

.actions {
  width: 100%;
  border-radius: 4px;
  padding: 20px 30px;
  background-color: #1e272e;
}
.actions li {
  color: #3c3c3c;
  height: 65px;
  width: 430px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  line-height: 65px;
  border-radius: 3px;
  display: inline-block;
  background-color: #fff;
  text-transform: uppercase;
  transition: 0.3s all ease-in-out;
}
.actions li:hover {
  color: #fff;
  background-color: #ffd32a;
}
.actions li button {
  color: #fff;
  background-color: #ffd32a;
  font-weight: bold;
}
.actions li:nth-child(1) {
  float: left;
}
.actions li:nth-child(2) {
  float: right;
}
.actions li span,
.actions li button {
  width: 100%;
  display: block;
  border: none;
}

.quiz-progress-area .progress {
  left: 0;
  right: 0;
  width: 100%;
  bottom: 140px;
  margin: 0 auto;
  max-width: 560px;
  overflow: visible;
  border-radius: 4px;
  position: absolute;
  background-color: #ffffff;
}
.quiz-progress-area .progress .progress-bar {
  border-radius: 4px;
  background-image: linear-gradient(14deg, #ffd32a 0%, #ffd32a 100%);
}
.quiz-progress-area .progress .progress-bar span {
  top: -65px;
  width: 275px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  border-radius: 4px;
  background-image: linear-gradient(14deg, #ffd32a 0%, #ffd32a 100%);
}
.quiz-progress-area .progress .progress-bar span:after {
  left: 50%;
  content: "";
  bottom: -10px;
  position: absolute;
  transform: translateX(-50%);
  border-top: 10px solid #ed1b34;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.progress-bar span {
  left: auto;
  right: -130px;
}

/*---------------------------------------------------- */
/*inner area*/
/*----------------------------------------------------*/
.quiz-top-area {
  padding: 35px 0px;
  background-color: #262626;
}
.quiz-top-area h1 {
  color: #fff;
  font-size: 25px;
}
.quiz-top-area .quiz-countdown {
  top: 15px;
  right: 10%;
  position: absolute;
}
.quiz-top-area .quiz-countdown li {
  width: 70px;
  height: 52px;
  margin-left: 12px;
  border-radius: 8px;
  position: relative;
  display: inline-block;
  background-color: #e6e6e6;
}
.quiz-top-area .quiz-countdown li:after {
  top: 0;
  right: -12px;
  content: ":";
  color: #ed1b34;
  font-size: 30px;
  position: absolute;
}
.quiz-top-area .quiz-countdown li:last-child:after {
  display: none;
}
.quiz-top-area .quiz-countdown .count-down-number {
  font-size: 38px;
  line-height: 50px;
  font-family: "Open Sans";
}
.quiz-top-area .quiz-countdown .count-unit {
  display: block;
  color: #ed1b34;
  margin-top: 5px;
  font-size: 16px;
  text-transform: uppercase;
}

.wizard-forms {
  padding: 90px 30px;
}

.quiz-title {
  margin: 0 auto;
  max-width: 800px;
}
.quiz-title span {
  font-size: 25px;
  color: #ed1b34;
}
.quiz-title h2 {
  color: #151515;
  font-size: 43px;
  font-weight: 700;
  padding-bottom: 20px;
  font-family: "Poppins";
}
.quiz-title p {
  color: #151515;
  font-size: 24px;
  font-weight: 500;
}

.quiz-option-selector {
  margin: 0 auto;
  max-width: 1135px;
  padding-top: 0px;
  padding-bottom: 80px;
}
.quiz-option-selector li {
  width: 50%;
  float: left;
  padding: 7px;
}
.quiz-option-selector li label {
  z-index: 2;
  width: 100%;
  height: 90px;
  padding: 10px;
  cursor: pointer;
  position: relative;
  border-radius: 4px;
  background-color: #fff;
  border: 2px solid #e7e7e7;
}
.quiz-option-selector li label .exp-number {
  float: left;
  width: 75px;
  height: 68px;
  z-index: 2;
  display: block;
  color: #1e272e;
  font-size: 30px;
  font-weight: 700;
  line-height: 68px;
  position: relative;
  margin-right: 45px;
  text-align: center;
  background-color: #fff;
  border: 2px solid #e7e7e7;
}
.quiz-option-selector li label .exp-label {
  z-index: 2;
  color: #1e272e;
  font-size: 23px;
  font-weight: 600;
  line-height: 70px;
  position: relative;
}
.quiz-option-selector li label .checkmark-border {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  position: absolute;
}
.quiz-option-selector li label .checkmark-border:before,
.quiz-option-selector li label .checkmark-border:after {
  top: 0;
  left: 0;
  z-index: -1;
  content: "";
  width: 100%;
  height: 100%;
  display: none;
  border-radius: 4px;
  position: absolute;
  background-image: linear-gradient(14deg, #ffd32a 0%, #ffd32a 100%);
}
.quiz-option-selector li label .checkmark-border:after {
  background-image: inherit;
  box-shadow: 0px 1px 38px 0px rgba(2, 2, 2, 0.27);
}
.quiz-option-selector li .exp-option-box {
  display: none;
}
.quiz-option-selector li .exp-option-box:checked ~ .checkmark-border:before,
.quiz-option-selector li .exp-option-box:checked ~ .checkmark-border:after {
  display: block;
}
.quiz-option-selector li .exp-option-box:checked ~ .exp-label {
  color: #fff;
}

.quiz-option-selector-2 li {
  padding: 15px;
}
.quiz-option-selector-2 li label {
  height: 85px;
}
.quiz-option-selector-2 li label .exp-number {
  border: none;
  margin-right: 0;
  line-height: 60px;
}
.quiz-option-selector-2 li label .checkmark-border:before {
  top: 23px;
  left: auto;
  right: 23px;
  width: 35px;
  height: 35px;
  display: block;
  border: 2px solid #e3e3e3;
  background-image: none;
}
.quiz-option-selector-2 li .exp-option-box:checked ~ .exp-label {
  color: #446d76;
}
.quiz-option-selector-2 li .exp-option-box:checked ~ .checkmark-border:before {
  background-image: linear-gradient(14deg, #ffd32a 0%, #ffd32a 100%);
}
.quiz-option-selector-2 p {
  float: right;
  font-size: 17px;
  font-weight: 700;
  color: #ed1b34;
  font-family: "Poppins";
  border-bottom: 2px solid #ed1b34;
}

.quiz-details-option {
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #fff;
  border: 2px solid #e7e7e7;
  padding: 55px 80px 45px 115px;
}
.quiz-details-option span {
  font-size: 24px;
}
.quiz-details-option .exp-option-box {
  display: none;
}
.quiz-details-option .select-area {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  position: absolute;
}
.quiz-details-option .select-area:before {
  top: 50%;
  left: 40px;
  width: 35px;
  content: "";
  height: 35px;
  position: absolute;
  border-radius: 100%;
  transform: translateY(-50%);
  box-shadow: inset 0px 5px 10px 0px rgba(206, 206, 206, 0.46);
}
.quiz-details-option .select-area:after {
  top: 50%;
  left: 35px;
  content: "";
  width: 45px;
  height: 45px;
  display: none;
  position: absolute;
  border-radius: 100%;
  transform: translateY(-50%);
  border: 2px solid #ed1b34;
}
.quiz-details-option .exp-option-box:checked ~ .select-area {
  border: 2px solid #ed1b34;
}
.quiz-details-option .exp-option-box:checked ~ .select-area:before {
  box-shadow: none;
  background-color: #ed1b34;
}
.quiz-details-option .exp-option-box:checked ~ .select-area:after {
  display: block;
}

.quiz-option-area .exp-option-box {
  display: none;
}
.quiz-option-area span {
  display: block;
}
.quiz-option-area .quiz-option-serial {
  left: 10px;
  width: 40px;
  color: #fff;
  height: 40px;
  bottom: 75px;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  position: absolute;
  background-color: #4c4c4c;
}
.quiz-option-area .quiz-option-img {
  border: 1px solid transparent;
}
.quiz-option-area .quiz-option-img img {
  width: 100%;
}
.quiz-option-area .quiz-option-text {
  color: #151515;
  font-size: 18px;
  padding: 20px 0px 20px 70px;
}
.quiz-option-area .exp-option-box:checked ~ .quiz-option-serial {
  background-color: #ed1b34;
}
.quiz-option-area .exp-option-box:checked ~ .quiz-option-img {
  border: 1px solid #ed1b34;
}

.thankyou-msg p {
  font-size: 20px;
  padding: 25px 0px 20px;
}
.thankyou-msg h2 {
  margin: 0 auto;
  font-size: 70px;
  max-width: 565px;
  font-weight: 700;
}

/*---------------------------------------------------- */
/*Responsive area*/
/*----------------------------------------------------*/
/* ==================================================
*	01 - media screen and (max-width: 1440px)
*	02 - media screen and (max-width: 1280px)
*	03 - media screen and (max-width: 1199px)
*	04 - media screen and (max-width: 991px)
*	05 - media screen and (max-width: 767px)
*	06 - media screen and (max-width: 680px)
*	07 - media screen and (max-width: 580px)
*	08 - media screen and (max-width: 480px)
*	09 - media screen and (max-width: 380px)
*	10 - media screen and (max-width: 320px)
================================================== */
@media screen and (max-width: 1440px) {
  .actions li {
    width: 255px;
  }

  .quiz-progress-area .progress {
    max-width: 400px;
  }
}
@media screen and (max-width: 991px) {
  .quiz-top-area .quiz-countdown {
    position: static;
    margin-top: 30px;
  }

  .quiz-option-selector li {
    width: 100%;
  }

  .quiz-progress-area .progress {
    position: static;
    margin-bottom: 50px;
  }

  .bottom-vector {
    display: none;
  }

  .quiz-progress-area .progress .progress-bar span {
    width: 145px;
  }

  .progress-bar span {
    right: -70px;
  }
}
@media screen and (max-width: 580px) {
  .actions li:nth-child(2),
  .actions li:nth-child(1) {
    float: none;
    margin: 0 auto;
    width: 250px;
    height: 50px;
    font-size: 16px;
    font-weight: 500;
    line-height: 50px;
  }

  .actions li:nth-child(1) {
    margin-bottom: 20px;
  }

  .actions {
    text-align: center;
  }

  .quiz-details-option span {
    font-size: 18px;
  }
}
@media screen and (max-width: 480px) {
  .quiz-progress-area .progress .progress-bar span {
    display: none;
  }

  .quiz-title h2 {
    font-size: 30px;
  }

  .quiz-title span,
  .quiz-title p,
  .quiz-option-selector li label .exp-label {
    font-size: 20px;
  }

  .quiz-option-selector li label .exp-number {
    margin-right: 15px;
  }

  .quiz-option-selector-2 li label .exp-number {
    width: 35px;
  }

  .wizard-forms {
    padding: 50px 15px;
  }

  .quiz-details-option {
    padding: 55px 20px 45px 95px;
  }

  .thankyou-msg h2 {
    font-size: 40px;
  }
}
/*---------------------------------------------------- */
