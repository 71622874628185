#logo {
  padding-bottom: 1em;
}

.wizard-forms {
  padding-top: 30px;
}

.quiz-option-selector {
  padding-bottom: 40px;
}

.progress {
  background-color: #ffffff !important;
}

.quiz-option-selector li .exp-option-box:checked ~ .exp-label {
  color: #1e272e;
}
