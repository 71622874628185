.leaflet-container {
  width: 100%;
  height: 100vh;
}
.nav-tabs .nav-link:hover {
  background: #dcdcdc !important;
}
.night-mode .nav-tabs .nav-link:hover {
  background: #ffffff !important;
}
.pending-invoice-list li {
  padding-bottom: 1rem !important;
  border-bottom: none !important;
}
