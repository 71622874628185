.error-message {
  color: red;
  margin-bottom: 1rem;
  text-align: center;
}

.error-message-input {
  font-size: 12px;
  color: red;
  padding-top: 4px;
  padding-left: 4px;
}

.error-message span,
.error-message-input span {
  margin-left: 0.5rem;
}
